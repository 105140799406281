#global_result_container {
    background-color: #caedcd;
    border-radius: 10px;
    padding: 10px 20px;
}

#prod_container {
    margin-top: 10px;
    background-color: #cadffa;
    border-radius: 10px;
    padding: 10px 20px;
}

.prod-lev1_container {
    background-color: #9dc8ff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 10px;
}

.prod-lev1_container:first-child{
    margin-top: 0px;
}

.prod-lev2_container {
    background-color: #69a8fa;
    border-radius: 10px;
    padding: 10px 20px;
}

#cons_container {
    margin-top: 10px;
    background-color: #ffa9a9;
    border-radius: 10px;
    padding: 10px 20px;
}

.cons-lev1_container {
    background-color: #fd7d7d;
    border-radius: 10px;
    padding: 10px 20px;
}

.small-text{
    font-size: 10pt;
}

.emph-text{
    font-weight: 700;
    font-size: 14pt;
}

.italic-text{
    font-size: 14pt;
    font-style: italic;
}

.submit{
    /*container_PRIMARY:{
        backgroundColor: isHover ? '#1376bd' : '#0961a0'
    },*/
    
    width: 100%;
    height: 40px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    border:0 none;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}