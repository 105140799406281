.App {
  /*text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  */
  height: 100%;
  width: 100%;
}

.italic{
  font-style: italic;
}