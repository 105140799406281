#home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#1DE9B6, #0097A7);
}

#home .logo{
    width: 33%;
    max-width: 200px;
    margin-bottom: 10px;
    border-radius: 200px;
  }
  
#home .title{
    color: white;
    text-align: center;
  }
  
#home form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
#home  input{
    margin: 5px 0px;
  }
  
#home label input {
    margin-left: 10px;
  }
  
#home .submit {
    width: 100%;
    max-width: 300px;
  }
  
#home .errText {
    color: red;
  }