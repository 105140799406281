.form-group {
    background-color: #ffeacf;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-group p{
    font-style: italic;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 5px;
}

.form-group input{
    margin-left: 20px;
}

.form-group label{
    margin-left: 10px;
}